export default {
  // baseUrl: "http://43.136.45.69/front/",

  // baseUrl: "http://localhost:8081/front/",

  baseUrl: "https://www.bhdesigncn.com/front/",

  home: {
    getHomeInfo: "home/getHomeInfo",
    getFilings: "home/getFilings"
  },

  project: {
    getProjects: "project/getProjects",
    getDesc: "project/getDesc",
    getProjectsByTag: "project/getProjectsByTag",
    hasTheTagProjects: "project/hasTheTagProjects"
  },

  contact: {
    getContactInfo: "contact/getContactInfo",
    submitMessage: "contact/submitMessage"
  },

  news: {
    getNewsInfo: "news/getNewsInfo",
    getNewsRecordPage: "news/getNewsRecordPage",
    getDesc: "news/getDesc"
  },

  about: {
    getAboutInfo: "about/getAboutInfo"
  },

  projectType: {
    getProjectTypes: "projectType/getProjectTypes"
  }
};
