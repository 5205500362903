<template>
  <v-app>
    
    <v-main>
      <router-view/>
    </v-main>

    <div style="color: #666666;font-size: 10pt;padding-top: 30px;padding-bottom: 30px;text-align: center;">
      {{ filings }}
    </div>
  </v-app>
</template>

<script>
import { getFilings } from "./components/api/home";

export default {
  name: 'App',

  data() {
    return {
      filings: null
    };
  },

  mounted() {
    getFilings({}).then(res=>{
      if (res.data.state === 'ok') {
        this.filings = res.data.filings;
      }
    }).catch(err=>{
      console.log(err);
    })
  },
};
</script>
