import axios from "axios";
import api from "./api";

// 普通型axios实例
const instance = axios.create({
  baseURL: api.baseUrl,
  timeout: 20000,
  // 允许携带cookie，同时后端也需配置（不然带不上cookie）
  // 高版本浏览器，对于设置的cookie，必须设置 SameSite=None 且 设置 Secure，不然cookie设置无效
  // 或者关闭浏览器这一块默认设置（貌似只在本地测试时出现，看后续如何解决）
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  },
  // 将默认的请求体data里的数据（json格式），更改为表单形式（键值对）
  transformRequest: [
    function(data) {
      let ret = "";
      for (let it in data) {
        let key = encodeURIComponent(it);
        let value = encodeURIComponent(data[it]);
        ret += key + "=" + value + "&";
      }
      return ret;
    }
  ]
});

//添加请求拦截器（在发送之前要做些什么）
instance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    // 请求错误
    console.log(error);
    return Promise.reject(error);
  }
);

//添加响应拦截器（数据请求成功后做些什么）
instance.interceptors.response.use(
  response => {
    // if (
    //   response.data.state === "fail" &&
    //   (response.data.msg === "您未登录或登录已过期" ||
    //     response.data.msg === "非法的管理员或管理员已被禁用")
    // ) {
    //   // 这里应该清除数据，暂时不处理

    //   return;
    // }
    return response;
  },
  error => {
    // 响应错误
    console.log(error);
    return Promise.reject(error);
  }
);

// 普通型axios上传实例
const uploadInstance = axios.create({
  baseURL: api.baseUrl,
  timeout: 20000,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});

//添加请求拦截器（在发送之前要做些什么）
uploadInstance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    // 请求错误
    console.log(error);
    return Promise.reject(error);
  }
);

//添加响应拦截器（数据请求成功后做些什么）
uploadInstance.interceptors.response.use(
  response => {
    // if (
    //   response.data.state === "fail" &&
    //   (response.data.msg === "您未登录或登录已过期" ||
    //     response.data.msg === "非法的管理员或管理员已被禁用")
    // ) {
    //   // 这里应该清除数据
    //   return;
    // }
    return response;
  },
  error => {
    // 响应错误
    console.log(error);
    return Promise.reject(error);
  }
);

export function get(url, params) {
  return instance.get(url, params);
}

export function post(url, params) {
  return instance.post(url, params);
}

export function upload(url, formData) {
  return uploadInstance.post(url, formData);
}
