import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'

import "swiper/css";

Vue.config.productionTip = false

const routerPush = VueRouter.prototype.push;
const routerReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(err=>{});
};
VueRouter.prototype.replace = function (location) {
  return routerReplace.call(this, location).catch(err=>{});
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
