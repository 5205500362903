import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue')
  },{
    path: '/project',
    name: 'project',
    component: () => import('../views/project/index.vue')
  },{
    path: '/project/type',
    name: 'project_type',
    component: () => import('../views/project/type.vue')
  },{
    path: '/project/desc',
    name: 'project_desc',
    component: () => import('../views/project/desc.vue')
  },{
    path: '/news',
    name: 'news',
    component: () => import('../views/news/index.vue')
  },{
    path: '/news/desc',
    name: 'news_desc',
    component: () => import('../views/news/desc.vue')
  },{
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue')
  },{
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact/index.vue')
  },{
    path: '/search/tag',
    name: 'search_tag',
    component: () => import('../views/search/tag.vue')
  },{
    path: '/search/key',
    name: 'search_key',
    component: () => import('../views/search/key.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
