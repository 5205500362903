import { post } from "./http";
import api from "./api";

export function getHomeInfo(params) {
    return post(api.home.getHomeInfo, params);
}

export function getFilings() {
    return post(api.home.getFilings);
}
  
